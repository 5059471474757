<template>
  <div class="mb-4">

    <div
      v-if="educator"
      class="row mt-4"
    >
      <div class="col-md-3">
        <img
          :src="educator.image"
          class="img-fluid"
        />
      </div>
      <div class="col-md-9">
        <h3><span class="text-danger">Educator: </span>{{educator.name}}</h3>
        <div><i class="fa fa-instagram mr-1" />{{educator.instagram.replace('https://www.instagram.com/','').replace('/','')}}</div>
        <div class="mt-4">{{educator.bio}}</div>
        <h4
          v-if="posts.length"
          class="mt-4 text-danger"
        >Videos</h4>
        <div class="row mt-4">
          <div
            class="col-md-4"
            v-for="post in posts"
            :key="post.id"
          >

            <post-card :post="post"></post-card>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { db } from "@/db.js";
import PostCard from '../components/PostCard.vue';
export default {
  components: { PostCard },
  data () {
    return {

      educator: null,
      posts: null
    }
  },
  mounted () {
    this.getEducator();
  },
  methods: {
    getEducator () {



      db.collection('educators')
        .doc(this.$route.params.educatorId)
        .get()
        .then(snapshot => {
          const document = snapshot.data()
          // do something with document
          this.educator = document;
          this.getPosts();
        })

    },
    getPosts () {
      this.$bind(
        "posts",
        db
          .collection("posts")
          .where("Educator", "==", this.$route.params.educatorId)
          .orderBy("Educator")//needed for missing fields
        // .limit(12)
      ).then(function () { });
    }
  },
}
</script>

<style>
.profile-image img {
  padding: 10px;
  height: 375px;
  width: 375px;
}
</style>